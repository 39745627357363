import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useState, useEffect } from "react";
import useFormStore from "../../../store";
import { CSS } from "@dnd-kit/utilities";
import { FaAngleLeft, FaAngleRight, FaGrip, FaTrash } from "react-icons/fa6";
import uniqid from "uniqid";
import { AddMoreBtn } from "./DRYComponents";
import { getWorkObj } from "../../../helpers/utils";
import { GoogleGenerativeAI } from "@google/generative-ai";


const job1Resp = [
  'Led a team of 10 developers in the successful design, development, and delivery of a scalable and high-performance SaaS platform, resulting in a 30% increase in user engagement and a 20% reduction in response time.',
  `Architected and implemented a microservices-based architecture using Node.js and Docker, resulting in a more flexible and maintainable system and enabling seamless integration with third-party services.`,
  'Core responsibility #3. Pretend this is where they stop reading. First 3 things should be most impressive',
  'Core responsibility #4.'
];

const jobResp = [
  'Core responsibility #1.',
  'Core responsibility #2.',
  'Core responsibility #3.',
  'Core responsibility #4.',
];

function BulletSortable({ bulletText, id, index, jobIndex, handleChange, removeBullet }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const respList = jobIndex === 0 ? job1Resp : jobResp;

  return (
    <div className="bullet-div" ref={setNodeRef} style={style}>
      <div className="bullet-order-div">
        <button type="button" className="grip-btn" {...attributes} {...listeners}><FaGrip /></button>
      </div>
      <input type="text" value={bulletText} onChange={(e) => { handleChange(e, index) }}
        placeholder={respList[index]}></input>
      <button type="button" className="bullet-remove-btn" onClick={() => { removeBullet(id) }}>Remove</button>
    </div>
  );
}

function JobForm({ index, currentIndex}) {
  const [work, setWorkAt] = useFormStore((state) => [
    state.work[index],
    state.setWork
  ]);

  const [isPresent, setIsPresent] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "durationFrom" || name === "durationTo") {
      // If durationFrom or durationTo changes, concatenate their values
      setWorkAt({
        ...work,
        [name]: value,
        duration: {
          ...work.duration,
          [name]: value
        },
      }, index);
    } else {
      // For other inputs, directly update the value
      setWorkAt({
        ...work,
        [name]: value,
      }, index);
    }
  };


  useEffect(() => {
    setIsPresent(work.duration.durationTo === "present");
  }, [work.duration.durationTo]);

  const handlePresentCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsPresent(isChecked);
    setWorkAt(
      {
        ...work,
        duration: {
          ...work.duration,
          durationTo: isChecked ? "present" : "",
        },
      },
      index
    );
  };



  const addNewLine = () => {
    setWorkAt({
      ...work,
      responsibilities: work.responsibilities + "\n",
    }, index);
  };

  return (
    <div className="work-body">
      <label>
        <span>Company Name</span>
        <input
          type="text"
          name="name"
          placeholder="Metaverse Company"
          value={work.name}
          onChange={handleInputChange}
        ></input>
      </label>
      <label>
        <span>Job Title</span>
        <input
          type="text"
          name="title"
          placeholder="Principal Software Engineer"
          value={work.title}
          onChange={handleInputChange}
        ></input>
      </label>
      <label>
        <span>Duration</span>
        <span style={{fontSize: '14px', color: '#0a52cf'}}>Start Date</span>
        <input
          type="month"
          name="durationFrom"
          placeholder="Starting date"
          value={work.duration.durationFrom}
          onChange={handleInputChange}
        />

        <div style={{textAlign: "center"}}>
        {!index && ( // Render checkbox only for the first index
          <>
            <input
              type="checkbox"
              name="presentCheckbox"
              checked={isPresent}
              onChange={handlePresentCheckboxChange}
            />
           <span style={{ marginLeft: '8px' }}>Present</span>
          </>
        )}
        </div>
         <span style={{fontSize: '14px', color: '#0a52cf'}}>Finish Date</span>
        <input
          type="month"
          placeholder="Ending date"
          name="durationTo"
          value={work.duration.durationTo}
          onChange={handleInputChange}
          disabled={isPresent} // Disable input when checkbox is checked
        />
      </label>
      <div className="responsibilities-container" style = {{width: "100%",textAlign: 'left', paddingLeft: '5%'}}>
        <p>Responsibilities</p>
        <textarea style = {{width: "100%", height: "100%", borderRadius: '10px'}}
          name= "responsibilities"
          className="responsibilities-textarea"
          value={work.responsibilities}
          onChange={(e) => {
            setWorkAt({
              ...work,
              responsibilities: e.target.value,
            }, index);
          }}
          placeholder="Enter responsibilities here..."
        ></textarea>
        {/* <AddMoreBtn
          divClass={"bullet-add-div"}
          btnClass={"bullet-add-btn"}
          clickHandler={addNewLine}
        /> */}
      </div>
    </div>
  );
}

function WorkExp() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [workArray, setWorkArray] = useFormStore((state) => [
    state.work,
    state.setWorkArray
  ]);
  const [value, setValue] = useState("");
const [submitting, setSubmitting] = useState(false);
const [summarize, setSummarize] = useState("");
const [size, setSize] = useState("small");
const NLPCloudClient = require('nlpcloud');

const handleSizeChange = (event) => {
  setSize(event.target.value);
};

const handlesubmit = async () => {
  setSubmitting(true);

  const client = new NLPCloudClient({ model: 'bart-large-cnn', token: '11900cdc0d664f92b27a4cf5ff5d8012232f76f1', gpu: false })
  try {
    const response = await client.summarization({
      text: value,
      size: size
    });

    // Update the summary state with the response data
    setSummarize(response.data.summary_text);
  } catch (err) {
    console.error(err.response.status);
    console.error(err.response.data.detail);
    // getting error code 429. It indicates the client application has surpassed its rate limit, or number of requests they can send in a given period of time.
  } finally {
    setSubmitting(false);
  }
};


  const addNewWork = () => {
    setWorkArray([
      ...workArray,
      getWorkObj(currentIndex + 1),
    ]);
    setCurrentIndex(currentIndex + 1);
    setValue("");
  };

  const deleteWork = () => {
    setWorkArray([
      ...workArray.slice(0, currentIndex),
      ...workArray.slice(currentIndex + 1),
    ]);
    setCurrentIndex(currentIndex - 1);
  };
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(""); // State to hold prompt input
  const [responses, setResponses] = useState([]); // State to hold generated response

  const genAI = new GoogleGenerativeAI("AIzaSyAyoomO86Rt2X9pAxU4sca0SujPzvqpBEY");

  const handleSubmitPrompt = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true before the asynchronous call
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });
      const result = await model.generateContent(prompt);
      const generatedResponse = await result.response.text();
      setResponses(prevResponses => [...prevResponses, generatedResponse]); // Append new response to the previous responses
    } catch (error) {
      console.error("Error generating response:", error);
      setResponses(prevResponses => [...prevResponses, "Error generating response. Please try again."]); // Append error message to the previous responses
    } finally {
      setLoading(false); // Set loading back to false after the asynchronous call
    }
  };

  const handleDeleteResponse = (index) => {
    setResponses(prevResponses => prevResponses.filter((_, i) => i !== index));
  };


  return (
    <div>
    <div className="work-container">
      <div className="work-header">
        <div className="work-indicator">
          <h4>Job {currentIndex + 1}</h4>
          <span>{currentIndex === 0 ? 'Most recent first' : null}</span>
        </div>
        <div className="work-nav">
          {currentIndex > 0 && (
            <button type="button" className="work-del" onClick={deleteWork}>
              <FaTrash />
            </button>
          )}
          {currentIndex > 0 && (
            <button
              type="button"
              onClick={() => setCurrentIndex(currentIndex - 1)}
            >
              <FaAngleLeft />
            </button>
          )}
          {currentIndex < workArray.length - 1 && (
            <button
              type="button"
              onClick={() => setCurrentIndex(currentIndex + 1)}
            >
              <FaAngleRight />
            </button>
          )}
          {currentIndex === workArray.length - 1 && (
            <button type="button" onClick={addNewWork}>
              +
            </button>
          )}
        </div>
      </div>
      {/* Render the JobForm component with the current index */}
      <JobForm index={currentIndex} />
    </div>
    <div className="prompt-container">
      <div>
        <label className="prompt-label">
          <span style={{ fontWeight: 700, color: "#0a52cf", display: 'block' }}>TEXT FORMATTER (Just add • before each point)</span>
          <textarea
            type="text"
            placeholder="Enter text for formatting..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </label>
        <button className="submit-btn" onClick={handleSubmitPrompt}>Generate Response</button>
      </div>
      {loading && <p>Please wait...</p>}
      {responses.map((response, index) => (
        <div key={index} className="response-container">
          <p className="response-heading">GENERATED RESPONSE:</p>
          <textarea className="response-textarea" value={response}></textarea>
          <button className="close-btn" onClick={() => handleDeleteResponse(index)}>X</button>
        </div>
      ))}
    </div>
      </div>
  );
}

export default WorkExp;