import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  bullet: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 17,
    paddingBottom: 8,
  },
  bulletText: {
    fontSize: 10,
    maxWidth: '95%',
  },
  bulletDot: {
    fontSize: 10,
    verticalAlign: 'super',
    paddingTop: 9,
    lineHeight: 0.8,
  }
})

function Bullet({ bullet }) {
  return (
    <View style={styles.bullet}>
      <Text style={styles.bulletDot}>•   </Text>
      <Text style={styles.bulletText}>
        { bullet.text }
      </Text>
    </View>
  );
}

export default Bullet;
