import { Text, View, StyleSheet } from '@react-pdf/renderer';
import commonStyles from "./commonStyles";
import Bullet from "./Bullet";

const styles = StyleSheet.create({
  italicText: {
    fontStyle: 'italic',
  },
});


function formatDate(dateString, isPresent) {
  if (isPresent) return 'Present'; // Return 'Present' if isPresent is true
  if (!dateString) return ''; // Return empty string if no date provided

  const date = new Date(dateString);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${month} ${year}`;
}

function WorkItem({ work }) {
  const { duration } = work;
  const isPresent = duration.durationTo === 'present';
  return (
    <>
      <View style={{...commonStyles.workHeaderLine, }}>
        <Text style={{ ...styles.italicText, fontSize: '10px'}}>{work.name}</Text>
        <Text style={{ ...styles.italicText, fontSize: '10px'}}>
          {duration ?
            `${formatDate(duration.durationFrom)} - ${formatDate(duration.durationTo, isPresent)}`
            : ''
          }
        </Text>
      </View>
      <View style={commonStyles.workHeaderLine}>
        <Text style={{ fontSize: '10px'}}>{work.title}</Text>
      </View>
      {work.responsibilities && (
        <View style={commonStyles.bulletBlock}>
          <Text style={{ fontSize: '11px'}}>Responsibilities:</Text>
          <View>
          <Text style={{ fontSize: '10px', marginLeft: '17px', textAlign: 'justify', marginBottom: 6 }}>{work.responsibilities}</Text>
          </View>
        </View>
      )}
    </>
  );
}

function PdfWork({ workArray }) {
  const workItems = workArray.map((work, index) => (<WorkItem work={work} key={index} />));

  return (
    <View>
      <View style={commonStyles.heading}><Text>Professional Experience</Text></View>
      {workItems}
    </View>
  );
}

export default PdfWork;
