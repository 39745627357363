import './IntroPage.css';

function IntroPage({ isFirstVisit, getStartedFn }) {
  return (
    <div className="intro-container">
      <h1 className="intro-head">
        
        <span className="head-text">
        <span>MRG </span>
        <span>Resume </span>
        <span>Template.</span>
      </span>
      </h1>
      {/* <p className="intro-desc">FOR OUR KING UZAIR BHAI!!!.</p> */}
      <div className="start-btn-container">
        <button type="button" onClick={getStartedFn} className="get-started-btn">Less GOoooo!</button>
      </div>
    </div>
  );
}

export default IntroPage;