import { StyleSheet, Text, View } from "@react-pdf/renderer";
import commonStyles from "./commonStyles";
import Bullet from "./Bullet";

const styles = StyleSheet.create({
  projectHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    color: "#2E5395",
  },
  projectTitle: {
    color: "#2E5395",
    fontSize: 12,
    fontWeight: 700,
  },
  projectText: {
    display: "flex",
    flexDirection: "row", 
    justifyContent: "flex-start", 
    marginBottom: 5, 
  },

  bulletBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    marginBottom: 2,
  },
});

function Project({ project }) {
  const bullets = project.bullets.map((bullet) => (
    <Bullet bullet={bullet} key={bullet.id} />
  ));

  const mid = Math.ceil(bullets.length / 2);
  const firstColumn = bullets.slice(0, mid);
  const secondColumn = bullets.slice(mid);

  return (
    <View style={styles.projectText}>
      <View style={{...styles.bulletBlock, paddingRight: 125}}>{firstColumn}</View>
      <View style={{...styles.bulletBlock}}>{secondColumn}</View>
    </View>
  );
}

function PdfProjects({ projectsArray }) {
  const projects = projectsArray.map((project, index) => (
    <Project project={project} key={index} />
  ));

  return (
    <View>
      <View style={commonStyles.heading}>
        <Text>Domains/ Industries</Text>
      </View>
      <View>
      {projects}
      </View>
    </View>
  );
}

export default PdfProjects;
