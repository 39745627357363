import useFormStore from "../../../store";
import { useState } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";

function Personal() {

  const personalData = useFormStore((state) => state.personal);
  const handleInputChange = useFormStore((state) => state.changePersonal);

 

  const summaryString = `As a Principal Software Engineer, I excel in designing and developing robust and scalable software solutions ...`;
  const { name, title, email,
    phone, summary, address } = personalData;


  // const [value, setValue] = useState("");
  // const [submitting, setSubmitting] = useState(false);
  // const [summarize, setSummarize] = useState("");
  // const [size, setSize] = useState("small");
  // const NLPCloudClient = require('nlpcloud');


  // const handleSummaryChange = (event) => {
  //   const newValue = event.target.value;
  //   const formattedValue = newValue.split('\n').map((line) => { 
  //     // Add bullet point if the line is not empty and doesn't start with one
  //     if (line && !line.startsWith('•')) {
  //       return `• ${line}`;
  //     }
  //     return line;
  //   }).join('\n');
  //   setValue(formattedValue);

  //   handleInputChange({
  //     target: {
  //       name: 'summary',
  //       value: formattedValue
  //     }
  //   });
  // };;

  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(""); // State to hold prompt input
  const [responses, setResponses] = useState([]); // State to hold generated response

  const genAI = new GoogleGenerativeAI("AIzaSyAyoomO86Rt2X9pAxU4sca0SujPzvqpBEY");

  const handleSubmitPrompt = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true before the asynchronous call
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });
      const result = await model.generateContent(prompt);
      const generatedResponse = await result.response.text();
      setResponses(prevResponses => [...prevResponses, generatedResponse]); // Append new response to the previous responses
    } catch (error) {
      console.error("Error generating response:", error);
      setResponses(prevResponses => [...prevResponses, "Error generating response. Please try again."]); // Append error message to the previous responses
    } finally {
      setLoading(false); // Set loading back to false after the asynchronous call
    }
  };

  const handleDeleteResponse = (index) => {
    setResponses(prevResponses => prevResponses.filter((_, i) => i !== index));
  };




  return (
    <div>
      <div className="personal-container">
        <div className="personal-input-container">
          <label><span>Full Name</span>
            <input type="text" name="name" placeholder="John Doe"
              onChange={handleInputChange} value={name}></input>
          </label>
          <label><span>Job Title</span>
            <input type="text" name="title" placeholder="Principal Software Engineer"
              onChange={handleInputChange} value={title}></input>
          </label>
          {/* <label><span>Email</span>
          <input type="email" name="email" placeholder="johndoe@xyz.com" onChange={handleInputChange}
            value={email}></input>
        </label>
        <label><span>Phone</span>
          <input type="text" name="phone" placeholder="944 704 8000" onChange={handleInputChange}
            value={phone}></input>
        </label> */}
        </div>
        {/* <label><span>Address<span> optional</span></span>
        <input type="text" name="address" placeholder="Blue Island, Metaverse" onChange={handleInputChange}
          value={address}></input>
      </label> */}
        <label className="summary-label"><span>Executive Summary •</span>
          <textarea name="summary" placeholder={summaryString} onChange={handleInputChange}
            value={summary}></textarea>
        </label>
      </div>
      <div className="prompt-container">
      <div>
        <label className="prompt-label">
          <span style={{ fontWeight: 700, color: "#0a52cf", display: 'block' }}>TEXT FORMATTER (Just add • before each point)</span>
          <textarea
            type="text"
            placeholder="Enter text for formatting..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </label>
        <button className="submit-btn" onClick={handleSubmitPrompt}>Generate Response</button>
      </div>
      {loading && <p>Please wait...</p>}
      {responses.map((response, index) => (
        <div key={index} className="response-container">
          <p className="response-heading">GENERATED RESPONSE:</p>
          <textarea className="response-textarea" value={response}></textarea>
          <button className="close-btn" onClick={() => handleDeleteResponse(index)}>X</button>
        </div>
      ))}
    </div>
    </div>
  );
}

export default Personal;