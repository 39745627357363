import { Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import commonStyles from "./commonStyles";
import githubIcon from "../../../assets/link-icons/github.png";
import linkedinIcon from "../../../assets/link-icons/linkedin.png";
import mailIcon from "../../../assets/link-icons/envelope-regular.png";
import webIcon from "../../../assets/link-icons/laptop-solid.png";
import fontSettings from "../../../assets/fontSettings";
import logo from '../../../assets/logo-img.png';



const styles = StyleSheet.create({
  header: {
    borderBottom: '0.5 solid #000',
    paddingBottom: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    /* height: 12%; */
    borderBottom: "0.5px solid black",

  },
  liveSubHead1: {
    fontWeight: 700,
    color: "#2E5395",
    justifyContent: "space-between",
    display: "flex",
  },
  name: {
    fontSize: 14,
    fontFamily: fontSettings.regularFont,
    fontWeight: 500,
  },
  title: {
    fontSize: '14px',
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 20,
    fontSize: '12px',
  },
  linksItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  icons: {
    color: '#0a52cf',
    height: 10,
    width: 10,
  },


  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  left: {
    flex: 1,
    color: "#2E5395",
  },
  right: {
    flex: 1,
    alignItems: 'flex-end',
    color: "#2E5395",
  },
})

// function Links({ email, links }) {
//   return (
//     <View style={styles.links}>
//       {email !== '' &&
//         <Link src={'mailto:' + email} style={styles.linksItem}>
//           <Image src={mailIcon} style={styles.icons} />
//           <Text style={commonStyles.link}>{email}</Text>
//         </Link>}
//       {links.web.link !== '' &&
//         <Link src={links.web.link} style={styles.linksItem}>
//           <Image src={webIcon} style={styles.icons} />
//           <Text style={commonStyles.link}>{links.web.text}</Text>
//         </Link>}
//       {links.linkedin.link !== '' &&
//         <Link src={links.linkedin.link} style={styles.linksItem}>
//           <Image src={linkedinIcon} style={styles.icons} />
//           <Text style={commonStyles.link}>{links.linkedin.text}</Text>
//         </Link>}
//       {links.github.link !== '' &&
//         <Link src={links.github.link} style={styles.linksItem}>
//           <Image src={githubIcon} style={styles.icons} />
//           <Text style={commonStyles.link}>{links.github.text}</Text>
//         </Link>}
//     </View>
//   )
// }

function HeaderSecondLine({ phone, address }) {
  return (
    <View style={styles.links}>
      <Text>{phone}</Text>
      <Text>{address}</Text>
    </View>
  )
}

function Header({ personal, links }) {
  const isSecondLine = personal.phone !== '' || personal.address !== '';

  return (
    <View style={styles.header}>

      <View>
        <View style={styles.container}>
          <View style={styles.left}>
            <Text style={{...styles.title, fontSize: '12px'}}>{personal.name}</Text>
          </View>
          <View style={styles.right}>
            <Text style={{...styles.title, fontSize: '10px'}}>{personal.title}</Text>
          </View>
        </View>
      </View>
      {/* { isSecondLine && <HeaderSecondLine phone={personal.phone} address={personal.address} /> }
      <Links email={personal.email} links={links} /> */}
    </View>
  )
}

export default Header;
