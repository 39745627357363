import { Text, View } from "@react-pdf/renderer";
import commonStyles from "./commonStyles";

function PdfCertInt({ certInt }) {
  return (
    <View>
      <View style={{ ...commonStyles.heading, display: 'flex', flexDirection: 'row' }}>
      {/* <Text>Other Information</Text> */}
        {/* {certInt.certification !== '' && <Text>Certificates </Text>}
        {certInt.certification !== '' && certInt.skills !== '' && <Text>, </Text>}
        {certInt.skills !== '' && <Text>Soft Skills </Text>}

        {(certInt.certification !== '' || certInt.skills !== '') && <Text>& </Text>}
        {certInt.interests !== '' && <Text>Interests</Text>} */}
      </View>
      {certInt.certification !== '' && <View style={{display: 'flex', flexDirection: "column" }}>
        <Text style={{...commonStyles.heading, marginBottom: "-4px", fontSize: "12px"}}>Certificates </Text>
        <Text style={{fontSize: "11px", paddingTop: '-4px', paddingBottom: '5px', marginLeft: 17}}>
        {'\n'}
          {certInt.certification}</Text>
      </View>}
      {certInt.skills !== '' && <View style={{display: 'flex', flexDirection: "column" }}>
        <Text style={{...commonStyles.heading, marginBottom: "-4px", fontSize: "12px"}}>Soft Skills </Text>
        <Text style={{fontSize: "11px", paddingTop: '-4px', paddingBottom: '5px', marginLeft: 17}}>
        {'\n'} 
        {certInt.skills}</Text>
      </View>}
      {certInt.interests !== '' && <View style={{display: 'flex', flexDirection: "column" }}>
        <Text style={{...commonStyles.heading, marginBottom: "-4px", fontSize: "12px"}}>Interests </Text>
        <Text style={{fontSize: "11px", paddingTop: '-4px', paddingBottom: '5px', marginLeft: 17}}>
        {'\n'}
        {certInt.interests}</Text>
      </View>}
    </View>
  );
}

export default PdfCertInt;
