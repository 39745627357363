import { StyleSheet } from "@react-pdf/renderer";
import fontSettings from "../../../assets/fontSettings";

const commonStyles = StyleSheet.create({
  bulletBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    marginBottom: 2,
  },

  italicText: {
    fontFamily: fontSettings.italicFont, 
    fontWeight: 500, 
  },

  heading: {
    color: "#2E5395",
    fontSize: 12,
    fontFamily: fontSettings.regularFont,
    fontWeight: 500,
    // borderBottom: '0.5 solid #000',
    marginBottom: 4,
    marginTop: 5,
  },
  workHeaderLine: {
    color: "#2E5395",
    display: 'flex',
    paddingTop: 5, 
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 12,
    // fontWeight: 500,
    // fontStyle: 'italic'
    
  },
  boldText: {
    fontSize: 12,
    fontWeight: 700,
  },
  normalTextLine: {
    fontSize: 8,
    display: 'flex',
    fontWeight: 500,
    flexDirection: 'row',
  },
  link: {
    color: '#0a52cf',
  },
});

export default commonStyles;
