import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({

  heading: {
    fontSize: 12,
    fontFamily: 'Garamond',
    fontWeight: 500,
    color: "#2E5395",
    marginBottom: 5,
  },

  bullet: {
    marginRight: 15,  
    marginLeft: 17
  },
  subheading: {
    fontSize: 12,
    fontWeight: 700,
  },
  skills: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column', 
    marginBottom: 4, 
  },
  skillBlock: {
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'flex-start', 
    flexWrap: 'wrap',
    fontSize: '12px'
  },

  // display: 'flex',
  // flexDirection: 'column',
  // marginTop: 2,
  // marginBottom: 2,

  skillLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center', 
    justifyContent: 'start',
    fontSize: 10,
  },
  allContent: {
    justifyContent: 'flex-start',
    alignItems: 'right',
    paddingLeft: 100
  },
});

function SkillItem({ text, index }) {
  if (text === '') return null;
  return (

    <View style={styles.skillLine}>
    
    <Text style={styles.bullet}>•</Text>

    <Text >{text}</Text>
  </View>

  );
}

function Skills({ lang }) {
  const maxSkillsPerColumn = Math.ceil(lang.length / 2);
  const skillRows = [];

  for (let i = 0; i < maxSkillsPerColumn; i++) {
    const row = (
      <View style={{ flexDirection: 'row' }} key={i}>
        <View style={{ flex: 1, paddingRight: 250, marginBottom: 5}}>
          {lang[i] && <SkillItem text={lang[i].text} index={i} />}
        </View>
        <View style={{ flex: 1}}>
          {lang[i + maxSkillsPerColumn] && (
            <SkillItem text={lang[i + maxSkillsPerColumn].text} index={i + maxSkillsPerColumn} />
          )}
        </View>
      </View>
    );
    skillRows.push(row);
  }

  return (
    <View style={styles.skillBlock}>
      {skillRows}
    </View>
  );
}
function SimpleSkills({ simpleSkills }) {
  const skillText = simpleSkills.map((skill) => (
    <Text key={skill}>{skill.name}</Text>
  ));

  return (
    <View style={styles.simpleSkills}>
      {skillText}
    </View>
  );
}

function PdfSkills({ isSimpleSkills, skills, simpleSkills }) {
  return (

   
    <View>
    <View style={styles.heading}><Text>Tools/ Skillset</Text></View>
    {/* style={{...styles.heading, marginLeft: '-465px'}} */}
    <div style={{justifyContent: "flex-start", alignItems: "start"}}>
      
      {isSimpleSkills ? (
        <SimpleSkills simpleSkills={simpleSkills} />
      ) : (
        <Skills lang={skills.lang} />
      )}
      </div>

    </View>
  );
}

export default PdfSkills;